:root {
    // Colors
    --primary: #2C38E1;
    --secondary: #2D58E0;
    --tertiary : #2D76E0;
    --quaternary : #2D95E0;
    --quinary : #2DB4E0;
    --red: #FE4658;
    --orange: #E1992D;
    --yellow: #E1D52D;
    --header: #2D2D2D;
    --text: #686868;
    --off-white: #F0F1FD;
    --white: #FFFFFF;

    // Font size
    --links-font-size: 12px;
    --text-font-size: 20px;
}

body {
    background-color: var(--white);
    color: var(--text);
}

h1,
h2,
h3,
h4 {
    color: var(--header);
}

.home {
    display: flex;
    padding: 20px;

    .homeContainer {
        border-radius: 32px;
        padding: 20px 40px;
        background-color: var(--off-white);
        box-shadow: 0px 12px 48px -6px rgba(44, 56, 225, 0.06);
        flex: 6;

        .widgets {
            display: flex;
            padding: 20px 0;
            gap: 32px;
        }
    }
}