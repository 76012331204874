.sidebar {
    flex: 1;
    margin-right: 20px;
    // min-height: 100vh;
    min-width: 144px;

    .top {
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;

        .logo {
            color: var(--secondary);
            font-size: var(--text-font-size);
            cursor: pointer;

            strong {
                color: var(--primary);
            }
        }
    }

    .center {
        ul {
            list-style: none;
            padding: 0;
            margin: 0;

            .title {
                color: var(--text);
                font-size: var(--links-font-size);
                font-weight: bold;
                margin-top: 10px;
                margin-bottom: 5px;
            }

            li {
                display: flex;
                align-items: center;
                padding: 4px 8px;
                cursor: pointer;
                border-radius: 6px;
                transition: all 0.3s ease-in-out;

                &:hover {
                    background-color: var(--off-white);
                    box-shadow: 0px 12px 48px -6px rgba(44, 56, 225, 0.06);

                    span {
                        color: var(--header);
                    }

                    .icon {
                        color: var(--primary);
                    }
                }
            }
        }

        span {
            color: var(--text);
            padding-left: 10px;
            padding-top: 5px;
        }
    }

    .icon {
        color: var(--tertiary);
        font-size: var(--text-font-size);
    }

    .bottom {
        display: flex;
        align-items: center;
        margin: 10px;

        .colorOption {
            margin: 5px;
            width: 20px;
            height: 20px;
            border-radius: 6px;
            border: 1px solid var(--text);
            cursor: pointer;
            transition: all 0.3s ease-in-out;

            &:nth-child(1) {
                background-color: var(--off-white);
            }

            &:nth-child(2) {
                background-color: var(--header);
            }
        }
    }
}