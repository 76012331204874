.navbar {
    // height: 50px;
    display: flex;
    align-items: center;
    color: var(--text);
    font-size: var(--text-font-size);

    .wrapper {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .search {
            display: flex;
            align-items: center;
            padding: 4px 8px;
            border-radius: 6px;
            background-color: var(--white);

            input {
                border: none;
                outline: none;
                background: transparent;

                &::placeholder {
                    color: var(--text);
                    font-size: var(--links-font-size);
                }
            }
        }

        .items {
            display: flex;
            align-items: center;

            .item {
                display: flex;
                align-items: center;
                margin: 5px;
                position: relative;
            }
        }
    }

    .icon {
        color: var(--tertiary);
        font-size: var(--text-font-size);
    }

    .avatar {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: var(--white);
    }

    .counter {
        width: 14px;
        height: 14px;
        border-radius: 50%;
        background-color: var(--primary);
        color: var(--white);
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 11px;
        position: absolute;
        top: -5px;
        right: -5px;
    }
}