.widget {
    display: flex;
    flex: 1;
    padding: 10px;
    min-height: 100px;
    justify-content: space-between;
    background-color: var(--white);
    padding: 4px 8px;
    border-radius: 16px;
    box-shadow: 0px 12px 48px -6px rgba(44, 56, 225, 0.06);
    transition: all 0.3s ease-in-out;

    &:hover {
        color: var(--white);
        background: linear-gradient(145deg, var(--quinary) 0%, var(--primary) 100%);
        box-shadow: 0px 12px 48px -3px rgba(44, 56, 225, 0.32);

        .title, .icon {
            color: var(--yellow);
        }
    }

    .title {
        color: var(--text);
        font-size: var(--links-font-size);
        font-weight: bold;
        margin-top: 10px;
        margin-bottom: 5px;
    }

    .icon {
        color: var(--tertiary);
        font-size: var(--text-font-size);
    }

    .left, .right {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
}